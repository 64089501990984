import { debounce } from "lodash"

export function useBeta() {
    const route = useRoute()
    const router = useRouter()

    const rapidClickRequirement = 10
    const rapidClickCount = ref(0)
    const clearRapidClick = debounce(() => {
        rapidClickCount.value = 0
    }, 500)

    const isBeta = computed<boolean>(() => {
        return (
            route.query.beta === "true" ||
            sessionStorage.getItem("gf-beta") === "true"
        )
    })

    function clearBeta() {
        // Clear beta query param
        const query = { ...route.query }
        delete query.beta
        router.push({ query })

        // Clear beta in session
        const hasBetaInSession = sessionStorage.getItem("gf-beta")
        if (hasBetaInSession) {
            sessionStorage.removeItem("gf-beta")
            router.go(0)
        }
    }

    function setBetaInSession() {
        sessionStorage.setItem("gf-beta", "true")
    }

    function rapidClickTrigger() {
        rapidClickCount.value++
        if (rapidClickCount.value >= rapidClickRequirement) {
            setBetaInSession()
            router.go(0)
        } else clearRapidClick()
    }

    return { isBeta, clearBeta, rapidClickTrigger }
}
